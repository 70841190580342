import { useEffect, useContext } from "react";
import { UserContext } from "../../../context/userProvider";
import { BiAlarm } from "react-icons/bi";


export default function Wait(props) {
  const { socket, prefId, salaId, userId, setAlert, navigateTo, gerarTokenLiveKit, userConfig } =useContext(UserContext);

  useEffect(() => {
    if (socket) {
      socket.emit("waiting-join", {
        salaId,
        userId,
        username: userConfig.username,
        prefId,
      });
    }
  }, []);

  const navigateToTransmissao = async () => {
    await gerarTokenLiveKit(userConfig);
    navigateTo(`/transmissao`);
  }

  useEffect(() => {
    if (socket) {
      socket.on("user-allowed-peer", (id) => {
        if (userId === id) {
          setAlert({
            visible: false,
            title: "Positive",
            placeholder: "Você foi autorizado a entrar na sala",
          });
          navigateToTransmissao();
        }
      });
    }
    return () => {
      if (socket) socket.off("user-allowed-peer");
    };
  }, [socket]);

  const removeWaitingRoom = () => {
    socket.emit("remove-waiting-room", {
      salaId: salaId,
      userId: userId,
    });
    props.quitAlert();
  };

  const { alert } = props;
  return (
    <div className="background-modal">
      <div className="alert-container">
        <div className="alert-content">
          <BiAlarm className="alert-icon" />
          <span className="alert-span"> {alert.placeholder} </span>
        </div>
        <div
          onClick={removeWaitingRoom}
          className="alert-container-button-waiting"
        >
          Cancelar
        </div>
      </div>
    </div>
  );
}
