import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userProvider";
import { LiveKitRoom, VideoConference } from "@livekit/components-react";

const Transmissao = () => {
  const {
    room,
    userConfig,
    tokenLiveKit,
    navigateTo,
    socket,
    token,
    salaId,
    userId,
    isAdmin,
    setAlert,
    gerarTokenLiveKitRefresh,
  } = useContext(UserContext);
  const [controlChat, setControlChat] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const wsUrl = "wss://videoconf-dododkuv.livekit.cloud";

  useEffect(() => {
    // Seleciona o input usando a classe diretamente
    const input = document.querySelector(".lk-form-control.lk-chat-form-input");
    if (input) {
      const params = {
        className: input.className,
        value: input.value,
      };
      if (params.value === "") {
        setControlChat(false);
        return;
      } else {
        if (socket && controlChat)
          socket.emit("saveMessages", {
            salaId,
            participant: userConfig.username,
            msg: params.value,
          });
        setControlChat(false);
      }
    }
  }, [controlChat]);

  useEffect(() => {
    window.onpopstate = () => {
      console.log("onpopstate");
      if (socket) {
        socket.emit("refresh-participants", {
          salaId,
        });
      }
      navigateTo(`/${room}`);
    };
  }, []);

  useEffect(() => {
    window.onbeforeunload = markPageRefresh;
  }, []);

  const markPageRefresh = () => {
    console.log("markPageRefresh");
    localStorage.setItem("Vox-isPageRefreshing", "true");
    localStorage.setItem("Vox-joinAfterRefresh", "true");
  };

  const unmarkPageRefresh = () => {
    console.log("unmarkPageRefresh");
    localStorage.setItem("Vox-isPageRefreshing", "false");
    setIsRefreshing(false);
  };

  useEffect(() => {
    const isRefreshing = localStorage.getItem("Vox-isPageRefreshing") === "true";
    setIsRefreshing(isRefreshing);
    if (isRefreshing) {
      gerarTokenLiveKitRefresh();
      setTimeout(() => {
        unmarkPageRefresh();
      }, 3000);
    }
  }, []);

  return (
    <div>
      <LiveKitRoom
        audio={userConfig.audioEnabled}
        video={userConfig.videoEnabled}
        token={tokenLiveKit}
        serverUrl={wsUrl}
        data-lk-theme="default"
        style={{ height: "90dvh" }}
        onDisconnected={() => {
          console.log("Desconectado do teleVox");
          let isRefreshing = localStorage.getItem("Vox-isPageRefreshing") === "true";
          if (socket) {
            socket.emit("leave-room", {
              salaId,
              username: userConfig.username,
              userId,
            });
          }
          if(!isRefreshing) navigateTo(`/${room}`);
        }}
        onConnected={() => {
          console.log("Conectado ao teleVox");
          let joinAfterRefresh = localStorage.getItem("Vox-joinAfterRefresh") === "true";
          if (socket) {
            socket.emit("join-room", {
              salaId,
              username: userConfig.username,
              userId,
              remoteAudio: userConfig.audioEnabled,
              remoteVideo: userConfig.videoEnabled,
              joinAfterRefresh,
            });
          }
          localStorage.setItem("Vox-userConfig", JSON.stringify(userConfig));
          localStorage.setItem("Vox-room", room);
          localStorage.setItem("Vox-isAdmin", isAdmin);
          localStorage.setItem("Vox-salaId", salaId);
          localStorage.setItem("Vox-userId", userId);
          localStorage.setItem("Vox-token", token);
          localStorage.setItem("Vox-joinAfterRefresh", "false");
        }}
        onError={() => {
          setAlert({
            visible: true,
            title: "Erro",
            placeholder: "Erro ao conectar com servidor, tente novamente.",
            type: "negative",
          });
          navigateTo(`/${room}`);
        }}
        onMediaDeviceFailure={() => {
          console.log("Erro ao acessar dispositivo de mídia");
        }}
      >
        {isRefreshing ? (
          <div className="refreshing">
            <p>Reconectando...</p>
          </div>
        ) : (
          <VideoConference
            chatMessageFormatter={(msg) => {
              setControlChat(true);
              return msg;
            }}
          />
        )}
      </LiveKitRoom>
    </div>
  );
};

export default Transmissao;
