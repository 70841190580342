import React from "react";
import { UserProvider } from "./context/userProvider";
import { BrowserRouter } from "react-router-dom";
import Routeror from "./router/Routeror";
import Alert from "./components/Alert";
import Notify from "./components/Notificacao";
import "./App.css";
import "@livekit/components-styles";
import Logo from "./assets/images/logo_transparent_white.svg";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={Logo} alt="Vox City" className="App-logo" />
      </header>
      <main data-lk-theme="default" style={{ height: "90dvh" }}>
        <BrowserRouter>
          <UserProvider>
            <Notify />
            <Alert />
            <Routeror />
          </UserProvider>
        </BrowserRouter>
      </main>
    </div>
  );
}

export default App;
