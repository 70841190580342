import "./styles.css";

function Redirecionar() {
  return (
    <div className="home-container">
      <div className="informations">
        <h1>
          Para acessar uma sala de transmissão no Vox Video é necessário
          adquirir o link da sala diretamente na aplicação Vox Chat.
        </h1>
        <h2>Para acessar a aplicação Vox Chat, clique no botão abaixo.</h2>
        <a href="https://voxchat.voxcity.com.br/" target="_blank" rel="noreferrer">
          <button className="chat-button">VOX CHAT</button>
        </a>
      </div>
    </div>
  );
}

export default Redirecionar;
