import { useContext, useEffect, useState } from "react";
import { PreJoin } from "@livekit/components-react";
import { UserContext } from "../../context/userProvider";
const Login = () => {
  const { setRoom, login, setAlert } = useContext(UserContext);
  const [link, setLink] = useState("");

  useEffect(() => {
    const linkSala = window.location.pathname.replace("/", "");
    setRoom(linkSala);
    setLink(linkSala);
    localStorage.setItem("Vox-isPageRefreshing", "false");
  }, []);

  const handleSubimit = async (data) => {
    if (!link) {
      return;
    }
    await login(link, data);
  };

  return (
    <div style={{ display: "grid", placeItems: "center", height: "100%" }}>
      <PreJoin
        camLabel="Câmera"
        micLabel="Microfone"
        joinLabel="Entrar"
        userLabel="Nome"
        persistUserChoices={true}
        onError={(err) => {
          console.error("Erro ao conectar:", err);
          let errorString = err.toString();
          if(errorString.trim().toLowerCase().includes('notallowed')){
            setAlert({
              visible: true,
              title: "Erro",
              placeholder: "Permissão de câmera ou microfone não concedida. Por favor, conceda permissão para continuar.",
              type: "negative",
            });
          }
        }}
        onSubmit={handleSubimit}
      />
    </div>
  );
};

export default Login;
