import axios from "axios";

let url = 'https://video.voxcity.com.br';
//let url = 'http://localhost:5000'

const ApiUsers = axios.create({
  baseURL: url,
  headers: {
    "Access-Control-Allow-Origin": url,
  },
});

axios.defaults.withCredentials = true;

export default ApiUsers;
