import React, { useState } from "react";
import { BsFiles, BsXLg } from "react-icons/bs";
import { toast } from "react-toastify";

const Modal = ({ closeModal, linkSala, prefId, salaId }) => {
  const [statusMessage, setStatusMessage] = useState("");
  let prodUrl = "https://tele.voxcity.com.br/";
  //let devUrl = "http://localhost:3000/";

  const copyLink = () => {
    navigator.clipboard.writeText(prodUrl + linkSala);
    toast.success("Link copiado com sucesso!");
  };

  const getCookie = (name) => {
    return document.cookie.split(";").reduce((r, v) => {
      const parts = v.split("=");
      return parts[0].trim() === name ? decodeURIComponent(parts[1]) : r;
    }, "");
  };

  const openExternalLink = (url) => {
    try {
      // Verifica se estamos no ambiente Electron
      if (window.process && window.process.versions && window.process.versions.electron) {
        const { shell } = window.require('electron');
        shell.openExternal(url);
        setStatusMessage("Link aberto com sucesso no navegador padrão.");
      } else {
        // Se não estiver em Electron, redirecione usando window.location.href
        window.open(url, "_blank");
        setStatusMessage("Tentativa de abrir o link no navegador padrão.");
      }
    } catch (error) {
      setStatusMessage("Erro ao abrir o link: " + error.message);
    }
  };
  

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={closeModal}>
          <BsXLg className="bs-icon-close" />
        </button>
        <p>Sua sala foi criada, agora clique em entrar!</p>
        <div className="link-sala">
          <h3>Link da Sala:</h3>
          <a
            href={prodUrl + linkSala}
            target="_blank"
            rel="noreferrer"
            className="a-link"
          >
            {prodUrl + linkSala}
          </a>
          <BsFiles onClick={copyLink} className="bs-icon" />
        </div>

        <button
          className="button-entrar"
          onClick={() =>
            openExternalLink(`${prodUrl}customRoom/${prefId}/${salaId}/${linkSala}`)
          }
        >
          Entrar na Sala
        </button>

        <p>{statusMessage}</p>
      </div>
    </div>
  );
};

export default Modal;
