import { createContext, useState, useEffect, useCallback } from "react";
import ApiUsers from "../ApiUsers";
import { useNavigate } from "react-router";
import { io } from "socket.io-client";
import userJoinSound from "../assets/sounds/userJoinSound.mp3";

export const UserContext = createContext();

export function UserProvider({ children }) {
  const [alert, setAlert] = useState({
    visible: false,
    title: "",
    placeholder: "",
  });
  const [socket, setSocket] = useState(null);
  const [notification, setNotification] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [room, setRoom] = useState(null);
  const [userConfig, setUserConfig] = useState({});
  const [token, setToken] = useState(null);
  const [tokenLiveKit, setTokenLiveKit] = useState(null);
  const [userId, setUserId] = useState(null);
  const [prefId, setPrefId] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [salaId, setSalaId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };

  const gerarTokenLiveKit = async (data) => {
    if (!room || !data.username) {
      setAlert({
        visible: true,
        title: "Negative",
        placeholder: "Room ou userName não informado",
        type: "negative",
      });
      return;
    }

    try {
      const response = await ApiUsers.post("/gerarTokenLive", {
        room: room,
        username: data.username,
      });

      setTokenLiveKit(response.data.token);
      navigateTo(`/transmissao/${room}`);
    } catch (err) {
      console.error("Erro ao gerar token:", err);
      setAlert({
        visible: true,
        title: "Error",
        placeholder: "Erro ao gerar o token. Tente novamente.",
        type: "negative",
      });
    }
  };

  const gerarTokenLiveKitRefresh = async () => {
    let userConfigR = await JSON.parse(localStorage.getItem("Vox-userConfig"));
    let roomR = localStorage.getItem("Vox-room");
    let isAdminR = localStorage.getItem("Vox-isAdmin");
    let salaIdR = localStorage.getItem("Vox-salaId");
    let userIdR = localStorage.getItem("Vox-userId");
    let tokenR = localStorage.getItem("Vox-token");

    if (!userConfigR?.username || !roomR || !isAdminR || !salaIdR || !userIdR || !tokenR) {
      return;
    }
    setUserConfig(userConfigR);
    setRoom(roomR);
    setIsAdmin(isAdminR === "true");
    setSalaId(salaIdR);
    setUserId(userIdR);
    setToken(tokenR);
    try {
      const response = await ApiUsers.post("/gerarTokenLive", {
        room: roomR,
        username: userConfigR.username,
      });
      setTokenLiveKit(response.data.token);
    } catch (err) {
      console.error("Erro ao gerar token:", err);
    }
  };

  const login = async (linkNome, data) => {
    try {
      const response = await ApiUsers.post("/login", {
        linkNome,
        nameParticipant: data.username,
        userId,
        prefId,
      });

      if (response.data.error) {
        return setAlert({
          visible: true,
          title: "Erro",
          placeholder: response.data.error,
          type: "negative",
        });
      }

      setUserConfig({
        audioEnabled: data.audioEnabled,
        videoEnabled: data.videoEnabled,
        username: data.username,
      });

      setToken(response.data.token);
      setSalaId(response.data.roomID);
      // para rodar sem a necessidade de admin
      // await gerarTokenLiveKit(data);
      // navigateTo(`/transmissao`);

      if (response.data.isAdmin) {
        setPrefId(response.data.prefId);
        setIsAdmin(response.data.isAdmin);
        await gerarTokenLiveKit(data);
        navigateTo(`/transmissao`);
      } else {
        setAlert({
          visible: true,
          title: "Aguarde",
          placeholder: "Aguarde autorização para entrar na sala.",
          type: "wait",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const generateUniqueId = () => {
    const randomNumber = Math.floor(Math.random() * Math.pow(10, 8));
    return randomNumber + Math.pow(10, 8);
  };

  useEffect(() => {
    if (token && !socket) {
      let url = 'wss://video.voxcity.com.br';
      //let url = "ws://localhost:5000";
      const newSocket = io.connect(url, {
        query: {
          token: token,
        },
      });
      setSocket(newSocket);
    }
    return () => {};
  }, [socket, token]);

  useEffect(() => {
    if (socket)
      socket.on("user-not-allowed-peer", (id) => {
        if (userId === id) {
          setAlert({
            visible: true,
            title: "Negative",
            placeholder:
              "Sua entrada na sala não foi autorizada. Entre em contato com o administrador da sala.",
            type: "negative",
          });
        }
      });

    return () => {
      if (socket) socket.off("user-not-allowed-peer");
    };
  }, [socket]);

  useEffect(() => {
    const getCookie = (name) => {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + "=")) {
          return cookie.substring(name.length + 1);
        }
      }
      return null;
    };

    const userIdCookie = getCookie("userId");
    const prefIdCookie = getCookie("prefId");
    if (userIdCookie) {
      setUserId(userIdCookie);
    } else if (prefIdCookie) {
      setPrefId(prefIdCookie);
      const newUserId = generateUniqueId();
      setUserId(newUserId);
    } else {
      const newUserId = generateUniqueId();
      setUserId(newUserId);
    }
  }, []);

  useEffect(() => {
    if (socket && isAdmin) {
      socket.on("user-waiting-join", (user) => {
        const userWaitingJoin = {
          username: user.username,
          userId: user.userId,
        };
        setNotification({
          type: "acceptWaitRoom",
          waitingRoom: [userWaitingJoin],
        });
      });

      socket.on("waiting-room", (userWaitingJoin) => {
        setNotification({
          type: "acceptWaitRoom",
          waitingRoom: userWaitingJoin,
        });
      });
    }
    return () => {
      if (socket) {
        socket.off("user-waiting-join");
        socket.off("waiting-room");
      }
    };
  }, [socket, isAdmin]);

  const userDisconected = useCallback(({ userId, username }) => {
    if (username && userId)
      setNotification({
        text: `${username} saiu da sala`,
        type: "disconnect",
      });
  }, []);

  useEffect(() => {
    if (socket) {
      const handleUserJoined = ({ userId, username }) => {
        if (userId) {
          setNotification({
            text: `${username} entrou na sala`,
            type: "normal",
          });
          const song = new Audio(userJoinSound);
          song.volume = 0.1;
          song
            .play()
            .catch((error) =>
              console.error("Erro ao reproduzir o som:", error)
            );
        }
      };

      socket.on("user-joined", handleUserJoined);
      return () => {
        socket.off("user-joined", handleUserJoined);
      };
    }
  }, [socket]);

  useEffect(() => {
    if (socket) {
      socket.on("user-disconnected", userDisconected);
    }
    return () => {
      if (socket) socket.off("user-disconnected");
    };
  }, [socket]);

  return (
    <UserContext.Provider
      value={{
        alert,
        setAlert,
        socket,
        setSocket,
        notification,
        setNotification,
        notifications,
        setNotifications,
        room,
        setRoom,
        navigateTo,
        userConfig,
        setUserConfig,
        login,
        token,
        tokenLiveKit,
        gerarTokenLiveKitRefresh,
        gerarTokenLiveKit,
        userId,
        setUserId,
        prefId,
        setPrefId,
        isAdmin,
        setIsAdmin,
        salaId,
        setSalaId,
        isLoading,
        setIsLoading,
        showModal,
        setShowModal,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
