import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Normal(props) {
  const { userId } = props.notification;
  const [showUsersWaiting, setShowUsersWaiting] = useState([]);

  useEffect(() => {
    setShowUsersWaiting([...showUsersWaiting, userId]);
  }, [props.notification]);

  useEffect(() => {
    let verificar = showUsersWaiting?.some(
      (id) => id === props.notification.userId,
    );
    if (verificar) {
      toast(props.notification.text, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        newestOnTop: true,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: "dark",
      });
    }
  }, [showUsersWaiting]);

  return null;
}

export default Normal;
